<template>
  <div class="systemInfo wr">
    <div class="info-a wr f-cb">
      <div class="left">
        <div class="pinfo-container wr swiper-container-horizontal swiper-container-android">
        <div class="swiper-wrapper">
        <div class="swiper-slide swiper-slide-active">
          <img src="../../../img/shuiwu.jpg" alt="">
        </div>
        </div>
        </div>
        </div>
        <div class="right">
        <div class="tit-60 mc hnb">城乡一体化数字孪生水务系统</div>
        <div class="tit-18 t1 hnb">
        <p style="line-height: 1.75em; text-align: justify;">
          <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
            城乡一体化数字孪生水务系统是一种革命性的解决方案，旨在利用数字孪生技术整合并优化城乡供水服务。通过创建实时、动态的数字副本，系统能够模拟和监控整个水务网络，确保水资源的高效管理和合理分配。
          </span>
        </p>
        <p style="line-height: 1.75em;"><br></p>
        </div>
        <!-- <div class="wr dss">
          <a href="javascript:;" class="ibtn fill  wow fadeInRight play animated animated animated" data-video="">
            <div class="tit-16">查看视频</div>
            <div class="icon">
              <svg t="1685516153433" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2285" width="24" height="24">
                <path d="M675.328 117.717333A425.429333 425.429333 0 0 0 512 85.333333C276.352 85.333333 85.333333 276.352 85.333333 512s191.018667 426.666667 426.666667 426.666667 426.666667-191.018667 426.666667-426.666667c0-56.746667-11.093333-112-32.384-163.328a21.333333 21.333333 0 0 0-39.402667 16.341333A382.762667 382.762667 0 0 1 896 512c0 212.074667-171.925333 384-384 384S128 724.074667 128 512 299.925333 128 512 128c51.114667 0 100.8 9.984 146.986667 29.12a21.333333 21.333333 0 0 0 16.341333-39.402667zM456.704 305.92C432.704 289.152 405.333333 303.082667 405.333333 331.797333v360.533334c0 28.586667 27.541333 42.538667 51.370667 25.856l252.352-176.768c21.76-15.253333 21.632-43.541333 0-58.709334l-252.373333-176.768z m-8.597333 366.72V351.466667l229.269333 160.597333-229.269333 160.597333z" fill="#ffffff" p-id="2286"></path>
              </svg>
            </div>
          </a>
        </div> -->
      </div>
    </div>
    <div class="view">
      <div class="info-b wr">
        <div class="tit-48 mc lh1 hnb">产品特点</div>
        <div class="list f-cb">
          <div class="li"> 
            <div>
              <div class="icon">
                <img :src="require('@/img/productFeatures-icon.png')" alt="实时数据监控">
              </div>
              <div class="tit-24 t1 hnb">实时数据监控</div>
            </div>
            <div class="tit-16 t2 text-justify">
              <p style="line-height: 1.75em; text-align: justify;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                  系统实时采集并展示水流量、压力、水质等关键指标，帮助管理人员随时掌握水务系统的状态。
                </span>
              </p>
            </div>
          </div>
          <div class="li">
            <div class="icon">
              <img :src="require('@/img/productFeatures-icon.png')" alt="预测与优化">
            </div>
            <div class="tit-24 t1 hnb">预测与优化</div>
            <div class="tit-16 t2 text-justify">
              <p style="line-height: 1.75em; text-align: justify;"><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">系统通过分析历史和实时数据，预测供水需求和潜在问题(如管网泄漏或污染)，提前进行资源调配和风险管理。</span></p>
            </div>
          </div>
          <div class="li">
            <div class="icon">
              <img :src="require('@/img/productFeatures-icon.png')" alt="资源优化分配">
            </div>
            <div class="tit-24 t1 hnb">资源优化分配</div>
            <div class="tit-16 t2 text-justify">
              <p style="line-height: 1.75em; text-align: justify;"><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">通过先进算法模拟供水方案和应急响应策略，优化水资源分配，确保在需求高峰和紧急情况下的水资源供应。</span></p>
            </div>
          </div>
          <div class="li">
            <div class="icon">
              <img :src="require('@/img/productFeatures-icon.png')" alt="故障响应与维护">
            </div>
            <div class="tit-24 t1 hnb">故障响应与维护</div>
            <div class="tit-16 t2 text-justify">
              <p style="line-height: 1.75em; text-align: justify;"><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">数字孪生模型能够有效识别系统故障，快速定位问题，大幅缩短响应时间并降低维护成本。</span></p>
            </div>
          </div>
          <div class="li">
            <div class="icon">
              <img :src="require('@/img/productFeatures-icon.png')" alt="决策支持">
            </div>
            <div class="tit-24 t1 hnb">决策支持</div>
            <div class="tit-16 t2 text-justify">
              <p style="line-height: 1.75em; text-align: justify;"><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">系统基于数据分析，制定更有效的运营策略、应急响应计划和长期发展规划。</span></p>
            </div>
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        
    },
    mounted() {
        
    }
}
</script>
<style lang="less" scoped>
.mc {
    color: #0041c3;
}
.lh1 {
    line-height: 1;
}
.tit-48 {
    line-height: 1.8;
    font-size: 48px;
}
// web端样式
.systemInfo {
  background-image: url(../../../img/bg3.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  // padding-top: 100px;
  padding-bottom: 4.5rem;
  .info-a {
    padding-top: 7.38rem;
    padding-bottom: 6rem;
    padding-left: 9rem;
    .left {
      float: left;
      width: 44.8%;
      .pinfo-container {
          overflow: hidden;
      }
    }
    .right {
        float: left;
        width: 55.2%;
        padding: 32px 190px 0 89px;
        // padding-left: 2.225rem;
        // padding-right: 4.75rem;
        // padding-top: .8rem;
      .dss {
        .ibtn {
          margin-top: 1.675rem;
          margin-right: 1rem;
          .tit-16 {
            color: #fff;
          }
          .icon {
            height: 24px;
            transition: all 0.5s;
          }
        }
      }
      .mc {
        border-bottom: 1px solid rgba(0, 65, 195, .2);
        line-height: 1;
        padding-bottom: 2.5rem;
        position: relative;
      }
      .mc::after {
        content: "";
        width: 60px;
        height: 6px;
        background: #0041C3;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      .t1 {
        color: #000000;
        margin-top: 3.31rem;
      }
    }
  }
  .view {
    width: 100%;
    padding: 0 190px;
    .info-b {
      background-color: #fff;
      padding: 76px 118px;
      margin-top: 20px;
      .list {
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        .li {
            width: calc(39.5%);
            // width: calc((100% - 115px) / 2);
            margin-right: 115px;
            padding: 40px 0;
            border-bottom: 1px solid rgba(207, 214, 229, 1);
            .icon {
              display: inline-block;
              vertical-align: middle;
              margin-bottom: 0.63rem;
            }
            .t1 {
                display: inline-block;
                margin-top: 1.25rem;
                margin-bottom: 1rem;
                margin-left: 0.8rem;
                position: relative;
            }
        }
      }
    }
  }
}
// 移动端样式
@media screen and (max-width:650px) {
    .home-bg1 {
        margin-top: -44px;
        .cycle-wrapper {
            padding: 44px 0px;
            .content {
                padding: 5px;
                margin: 0 35px;
                .con-title {
                    font-size: 14px;
                }
                .con-text {
                    font-size: 14px;
                    line-height: 35px;
                }
            }
        }
    }
}
</style>
<style>
/* 移动端样式 */
@media screen and (max-width:650px) {
    .el-breadcrumb__inner {
        font-size: 14px !important;
    }
    .el-breadcrumb__separator {
        font-size: 14px !important;
    }
}
</style>