var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"systemInfo wr"},[_vm._m(0),_c('div',{staticClass:"view"},[_c('div',{staticClass:"info-b wr"},[_c('div',{staticClass:"tit-48 mc lh1 hnb"},[_vm._v("产品特点")]),_c('div',{staticClass:"list f-cb"},[_c('div',{staticClass:"li"},[_c('div',[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/img/productFeatures-icon.png'),"alt":"实时数据监控"}})]),_c('div',{staticClass:"tit-24 t1 hnb"},[_vm._v("实时数据监控")])]),_vm._m(1)]),_c('div',{staticClass:"li"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/img/productFeatures-icon.png'),"alt":"预测与优化"}})]),_c('div',{staticClass:"tit-24 t1 hnb"},[_vm._v("预测与优化")]),_vm._m(2)]),_c('div',{staticClass:"li"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/img/productFeatures-icon.png'),"alt":"资源优化分配"}})]),_c('div',{staticClass:"tit-24 t1 hnb"},[_vm._v("资源优化分配")]),_vm._m(3)]),_c('div',{staticClass:"li"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/img/productFeatures-icon.png'),"alt":"故障响应与维护"}})]),_c('div',{staticClass:"tit-24 t1 hnb"},[_vm._v("故障响应与维护")]),_vm._m(4)]),_c('div',{staticClass:"li"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/img/productFeatures-icon.png'),"alt":"决策支持"}})]),_c('div',{staticClass:"tit-24 t1 hnb"},[_vm._v("决策支持")]),_vm._m(5)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-a wr f-cb"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"pinfo-container wr swiper-container-horizontal swiper-container-android"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide swiper-slide-active"},[_c('img',{attrs:{"src":require("../../../img/shuiwu.jpg"),"alt":""}})])])])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"tit-60 mc hnb"},[_vm._v("城乡一体化数字孪生水务系统")]),_c('div',{staticClass:"tit-18 t1 hnb"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v(" 城乡一体化数字孪生水务系统是一种革命性的解决方案，旨在利用数字孪生技术整合并优化城乡供水服务。通过创建实时、动态的数字副本，系统能够模拟和监控整个水务网络，确保水资源的高效管理和合理分配。 ")])]),_c('p',{staticStyle:{"line-height":"1.75em"}},[_c('br')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit-16 t2 text-justify"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v(" 系统实时采集并展示水流量、压力、水质等关键指标，帮助管理人员随时掌握水务系统的状态。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit-16 t2 text-justify"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v("系统通过分析历史和实时数据，预测供水需求和潜在问题(如管网泄漏或污染)，提前进行资源调配和风险管理。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit-16 t2 text-justify"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v("通过先进算法模拟供水方案和应急响应策略，优化水资源分配，确保在需求高峰和紧急情况下的水资源供应。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit-16 t2 text-justify"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v("数字孪生模型能够有效识别系统故障，快速定位问题，大幅缩短响应时间并降低维护成本。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit-16 t2 text-justify"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v("系统基于数据分析，制定更有效的运营策略、应急响应计划和长期发展规划。")])])])
}]

export { render, staticRenderFns }